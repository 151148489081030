var token = $('meta[name="_token"]').attr('content');

$(document).ready(function(){
    $('input[name=product-idea-image]').change(function(e){
        var fileName = e.target.files[0].name;

        $('div#productIdeaFile').parent().show();
        $('div#productIdeaFile').text(fileName);
    });
});

$('#createProductIdeaBtn').click(function (e) {
    e.preventDefault();

    var productNameField = $('input[name=product-idea-product]');
    var purposeField = $('input[name=product-idea-purpose]');
    var departmentField = $('input[name=product-idea-department]');
    var emailField = $('input[name=product-idea-email]');
    var imageField = $('input[name=product-idea-image]');

    var data = new FormData();

    data.append('product_name', productNameField.val());
    data.append('purpose', purposeField.val());
    data.append('department', departmentField.val());
    data.append('email', emailField.val());

    const image = imageField[0].files[0];
    if (image) {
        data.append('image', image);
    }

    $('#createProductIdeaSuccess').hide();
    $('#createProductIdeaErrors').hide();
    $('#createProductIdeaErrors div').empty();

    $.ajax({
        headers: {'X-CSRF-TOKEN': token},
        url: '/shop/product-idea/create',
        data: data,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function (data) {
            productNameField.val('');
            purposeField.val('');
            departmentField.val('');
            emailField.val('');
            imageField.val(null);

            $('#createProductIdeaSuccess').show();
            $('div#productIdeaFile').parent().hide();
        },
        error: function (error) {
            if (error.status === 422) {
                $('#createProductIdeaErrors').show();

                var errors = error.responseJSON;
                errorHtml = '<div class="alert alert-danger"><ul class="list-unstyled">';

                $.each(errors, function(key, value) {
                    errorHtml += '<li>' + value[0] + '</li>';
                });

                errorHtml += '</ul></div>';
                $('#createProductIdeaErrors').html(errorHtml);
            }
        }
    });

});

