// Standard Theme JS

var variants = typeof variants !== 'undefined' ? variants : [];

var pageId = typeof pageId !== 'undefined' ? pageId : null;

var collapseElement = $('.collapse');
var changeText = $('.changeText');

collapseElement.collapse("hide");
collapseElement.on('hide.bs.collapse', function () {
    $('.shortTxt').show();
    changeText.text(changeText.attr('data-moreTxt'));
});
collapseElement.on('show.bs.collapse', function () {
    $('.shortTxt').hide();
    changeText.text(changeText.attr('data-lessTxt'));
});

$('.thumbs .fancybox').hover(function() {
    var $this = $(this);
    var $imageHref = $this.attr('href');
    var $imageTitle = $this.attr('title');
    var $imageSrc = $this.attr('data-src');
    var $imagePreview = $('.primary a');

    $imagePreview.attr('href', $imageHref);
    $imagePreview.attr('title', $imageTitle);
    $imagePreview.css({'background-image': 'url("' + $imageSrc + '")'});

    if ($this.attr('href').indexOf('www.youtube.com') !== -1) {
        $('.primary .fa').show();
    } else {
        $('.primary .fa').hide();
    }
});

$(".fancybox").click(function() {
    var $this = $(this);
    if ($this.attr('href').indexOf('www.youtube.com') !== -1) {
        $.fancybox({
            'padding': 0,
            'autoScale': false,
            'transitionIn': 'none',
            'transitionOut': 'none',
            'title': this.title,
            'width': 680,
            'height': 495,
            'href': this.href.replace(new RegExp("watch\\?v=", "i"), 'v/'),
            'type': 'swf',
            'swf': {
                'wmode': 'transparent',
                'allowfullscreen': 'true'
            }
        });
    } else {
        $.fancybox({
            'openEffect': 'none',
            'closeEffect': 'none',
            'autoDimensions': false,
            'width': 400,
            'height': 300,
            'title': this.title,
            'href': this.href,
            'type': 'image'
        });
    }

    return false;
});
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});
accounting.settings = {
    currency: {
        symbol: "kr",   // default currency symbol is '$'
        format: "%v %s", // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal: ",",  // decimal point separator
        thousand: ".",  // thousands separator
        precision: 2   // decimal places
    },
    number: {
        precision : 0,  // default precision on numbers is 0
        thousand: ".",
        decimal: ","
    }
};

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
        $(element).closest('div.checkbox').addClass('has-error');
        $(element).closest('div.radio').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
        $(element).closest('div.checkbox').removeClass('has-error');
        $(element).closest('div.radio').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});

$('#form-confirm').validate({
    rules: {
        terms: {
            required: true
        }
    }
});

$(window).load(function() {
    $('#newsletterModal').modal('show');
});

// Prevent dropdown from closing
$('.form-filter .dropdown-menu').click(function(event) {
    event.stopPropagation();
});

var filterQuery = {};
var timeoutFilter = null;

function setupFilters(filterForm) {
    var filterBrand = filterForm.find('input[name="filter_brand[]"]:checked').map(function() {
        return this.value;
    }).get();
    var filterPrice = filterForm.find('input[name="filter_price[]"]:checked').map(function() {
        return this.value;
    }).get();
    var sortPrice = filterForm.find('input[name="filter_sort"]:checked').val();

    filterQuery.brand = null;
    if (filterBrand.length) {
        filterQuery.brand = filterBrand.join();
    }

    filterQuery.price = null;
    if (filterPrice.length) {
        filterQuery.price = filterPrice.join();
    }

    filterQuery.sort = null;
    if (typeof sortPrice !== 'undefined' && sortPrice.length) {
        filterQuery.sort = sortPrice;
    }
}

$(document).on('change', '.form-filter input', function() {
    window.history.pushState("Pagination", "Pagination", resetPaginationParams());

    if (timeoutFilter) {
        clearTimeout(timeoutFilter);
        timeoutFilter = null;
    }
    var filterForm = $(this).closest('form');

    filterForm.find('input').closest('.checkbox').removeClass('active');
    filterForm.find('input[name="filter_brand[]"]:checked').closest('.checkbox').addClass('active');
    filterForm.find('input[name="filter_price[]"]:checked').closest('.checkbox').addClass('active');
    filterForm.find('input[name="filter_sort"]:checked').closest('.checkbox').addClass('active');

    setupFilters(filterForm);

    timeoutFilter = setTimeout(function() {
        refreshProducts();
    }, 700);
});

$(document).on('change keyup', 'input[name="postcode"]', function() {
    var post_code = $(this).val();
    if (post_code.length === 4 && $('select[name="country"]').val() === 'DK') {
        $.ajax({
            url: 'https://dawa.aws.dk/postnumre/?nr=' + post_code,
            type: 'GET',
            dataType: 'json',
            success: function(json) {
                if (json.length > 0 && typeof json[0].navn !== 'undefined') {
                    $('input[name="city"]').val(json[0].navn);
                } else {
                    $('input[name="city"]').val('');
                }
            }
        });
    } else {
        $('input[name="city"]').val('');
    }
});

$(document).on('change', ':input[name="shopDeliveryAddress"]', function() {
    if ($(this).val() > 0) {
        $(':input[name="company"]').val($(this).find(':selected').attr('data-delivery-company-name'));
        $(':input[name="address"]').val($(this).find(':selected').attr('data-delivery-address'));
        $(':input[name="postcode"]').val($(this).find(':selected').attr('data-delivery-zip-code'));
        $(':input[name="city"]').val($(this).find(':selected').attr('data-delivery-city'));
        $(':input[name="country"]').val($(this).find(':selected').attr('data-delivery-country-code'));
    } else {
        $(':input[name="company"]').val('');
        $(':input[name="address"]').val('');
        $(':input[name="postcode"]').val('');
        $(':input[name="city"]').val('');
        $(':input[name="country"]').val('');
    }
});

$(document).on('change', ':input[name="address"], :input[name="postcode"], :input[name="city"], :input[name="country"]', function() {
    $(':input[name="shopDeliveryAddress"]').val('');
});

function isOnScreen(element) {
    if ($(element).length) {
        var vpH = $(window).height();
        var st = $(window).scrollTop();
        var y = $(element).offset().top;
        var elementHeight = $(element).height();

        return ((y < (vpH + st)) && (y > (st - elementHeight)));
    }

    return false;
}

/*
* Define variables
*/
var productsContainer = $('.product-scroller');
var popularProductsContainer = $('.popular-scroller');
var newestProductsContainer = $('.newest-scroller');
var productsPopular = $('.row-popular');
var productsNewest = $('.row-newest');
var rowPrice = $('.row-price');
var rowPriceContainer = $('.row-price .price-flex');
var category = productsContainer.data('category');
var vendor = productsContainer.data('vendor');
var shopToken = $('meta[name="_token"]').attr('content');
var shopUrl = $('meta[name="_shop"]').attr('content');
var $variantId = 0;
var page= 1; //default value
var perPage = 24; //default value

$(function() {
    if (productsContainer.length) {
        var filterForm = $('.form-filter');
        if (filterForm.length) {
            filterForm = filterForm.first();
            setupFilters(filterForm);
        }
        loadProducts();
    }
    if (productsPopular.length) {
        loadPopularProducts();
    }
    if (productsNewest.length) {
        loadNewestProducts();
    }
    if (rowPrice.length) {
        loadPriceRange();
    }
});

function appendProducts(products) {
    var itemSetLength = products.length;
    var timeout = 0;
    var interval = timeout;
    $.each(products, function(index, value) {
        setTimeout(function() {
            productsContainer.append(value).find('.product-item').last().fadeIn(timeout * 2);
            if (index === itemSetLength - 1) {
                $('.product-loader i').hide();
                $('.form-filter button').attr('disabled', false);
            }
        }, interval);
        interval += timeout;
    });
}

function appendPopularProducts(products) {
    var timeout = 0;
    var interval = timeout;
    $.each(products, function(index, value) {
        setTimeout(function() {
            popularProductsContainer.append(value).find('.product-item').last().fadeIn(timeout * 2);
        }, interval);
        interval += timeout;
    });
}

function appendNewestProducts(products) {
    var timeout = 0;
    var interval = timeout;
    $.each(products, function(index, value) {
        setTimeout(function() {
            newestProductsContainer.append(value).find('.product-item').last().fadeIn(timeout * 2);
        }, interval);
        interval += timeout;
    });
}

function appendPriceRange(data) {
    rowPriceContainer.empty();
    var html = '';
    html += '<div class="price-flex-item">';
    html += '<a href="' + data.link + '">Se alle gaver</a>';
    html += '</div>';
    $.each(data.prices, function(index, value) {
        html += '<div class="price-flex-item">';
        html += '<a href="' + data.link + '?price=' + value.index + '">' + value.label + '</a>';
        html += '</div>';
    });
    rowPriceContainer.html(html);
}

function scrollToProducts() {
    var $offset = productsContainer.offset().top - $('.header-fixed-wrap').outerHeight() - 80;
    $('html, body').animate({scrollTop: $offset}, 700, 'swing');
}

//Set the correct values for the pagination links in the bottom of products container pages
function buildPaginationLinks(currentPage, totalPages) {
    var html = '';

    if (currentPage > 1) {
        html += '<a href="#" class="resource-page-prev">«</a>';
    }

    if((currentPage - 2) > 1) {
        html += '<a href="#"><span class="pagination-trigger" data-page="1">1</span></a>';
        html += '<p class="pagination-dots">..</p>';
    }

    if ((currentPage - 2) >= 1) {
        html += '<a href="#"><span class="pagination-trigger" data-page="' + (currentPage - 2) + '">' + (currentPage - 2) + '</span></a>';
    }

    if ((currentPage - 1) >= 1) {
        html += '<a href="#"><span class="pagination-trigger" data-page="' + (currentPage - 1) + '">' + (currentPage - 1) + '</span></a>';
    }

    if (totalPages > 1) {
        html += '<span class="pagination-current">' + currentPage + '</span>';
    }

    if ((currentPage + 1) <= totalPages) {
        html += '<a href="#"><span class="pagination-trigger" data-page="' + (currentPage + 1) + '">' + (currentPage + 1) + '</span></a>';
    }

    if ((currentPage + 2) <= totalPages) {
        html += '<a href="#"><span class="pagination-trigger" data-page="' + (currentPage + 2) + '">' + (currentPage + 2) + '</span></a>';
    }

    /*
    if(totalPages > (currentPage + 2)) {
        html += '<p class="pagination-dots">..</p>';
        html += '<a href="#"><span class="pagination-trigger" data-page="' + totalPages + '">' + totalPages +'</span></a>';
    }
     */

    if (currentPage < totalPages) {
        html += '<a href="#"><span class="resource-page-next">»</span></a>';
    }


    $('.category-pagination-bottom').empty().html(html);
    $('.vendor-pagination-bottom').empty().html(html);
}

//Call the neccesary functions for loading product page
function loadProducts() {
    //Get the params for pagination
    var params = getPaginationVariables();

    //ensure that the frontend select shows the correct value
    $('#showNumber').val(params.perPage);

    //actually send the request
    getProducts(params);
}

function resetPaginationParams() {
    return '?page=1&perPage=' + perPage;
}

function getPaginationParams() {
    //return the actual pagination parameters
    return '?page=' + page + '&perPage=' + perPage;
}

function setPaginationParams(mode) {
    if(mode == 'replace') {
        window.history.replaceState("Pagination", "Pagination", getPaginationParams());
    } else {
        window.history.pushState("Pagination", "Pagination", getPaginationParams());
    }
}

//Make sure the pagination variables is set before loading the products
function getPaginationVariables() {
    var url = new URL(window.location.href);
    var getPage = url.searchParams.get('page');
    var getPerPage = url.searchParams.get('perPage');

    if(isNumber(getPage) === false) {
        getPage = page;
    }

    if(isNumber(getPerPage) === false) {
        getPerPage = perPage;
    }

    return {
        'page': getPage,
        'perPage': getPerPage
    };
}

function isNumber(val) {
    return /^\d+$/.test(val);
}

//Make the AJAX call for the server
function getProducts(params) {
    $.ajax({
        headers: {'X-CSRF-TOKEN': shopToken},
        type: 'POST',
        url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/load-products',
        dataType: 'json',
        data: {
            category: category,
            vendor: vendor,
            page: params.page,
            filters: filterQuery,
            perPage: params.perPage,
        },
        beforeSend: function() {
            $('.product-loader i').show();
            $('.form-filter button').attr('disabled', true);
            $('.resource-pagination').hide();
            $('.category-pagination-bottom').empty();
            $('.vendor-pagination-bottom').empty();
        },
        success: function(json) {
            if (json.data.length) {
                appendProducts(json.data);
                $('.pagination-current').text(json.current_page);
                $('.pagination-total').text(json.total_pages);

                if (json.total_pages > 1) {
                    $('.resource-pagination').show();
                    buildPaginationLinks(json.current_page, json.total_pages);
                }
            } else {
                $('.product-loader i').hide();
                $('.form-filter button').attr('disabled', false);
                $('.product-loader span').show();
                if ($('.product-list-end').length) {
                    $('.product-loader').hide();
                }
                if (page === 1) {
                    productsContainer.html('<p class="text-center">Ingen produkter fundet</p>');
                }
            }
        }
    });
}

//Refresh products
window.onpopstate = function(e){
    timeoutFilter = setTimeout(function() {
        refreshProducts();
    }, 500);
};

//Change amount on product page
$(document).on('change', '#showNumber', function() {
    if (timeoutFilter) {
        clearTimeout(timeoutFilter);
        timeoutFilter = null;
    }

    perPage = $(this).val();
    setPaginationParams('replace');

    timeoutFilter = setTimeout(function() {
        refreshProducts();
    }, 500);
});

function processProductRequest() {
    productsContainer.empty();
    loadProducts();
    $('.product-loader i').show();
    $('.form-filter button').attr('disabled', true);
    $('.product-loader span').hide();
    scrollToProducts();
}

function refreshProducts() {
    if (productsContainer.length) {
        page = 1;
        processProductRequest();
    }
}

function loadPopularProducts() {
    $.ajax({
        headers: {'X-CSRF-TOKEN': shopToken},
        type: 'GET',
        url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/load-popular-products',
        dataType: 'json',
        data: {
            page_id: pageId
        },
        beforeSend: function() {
            $('.popular-loader').show();
        },
        success: function(json) {
            if (json.length) {
                productsPopular.show();
                appendPopularProducts(json);
            } else {
                productsPopular.hide();
            }
        },
        complete: function() {
            $('.popular-loader').hide();
        }
    });
}

function loadNewestProducts() {
    $.ajax({
        headers: {'X-CSRF-TOKEN': shopToken},
        type: 'GET',
        url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/load-newest-products',
        dataType: 'json',
        data: {},
        beforeSend: function() {
            $('.newest-loader').show();
        },
        success: function(json) {
            if (json.length) {
                productsNewest.show();
                appendNewestProducts(json);
            } else {
                productsNewest.hide();
            }
        },
        complete: function() {
            $('.newest-loader').hide();
        }
    });
}

function loadPriceRange() {
    $.ajax({
        headers: {'X-CSRF-TOKEN': shopToken},
        type: 'GET',
        url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/load-price-range',
        dataType: 'json',
        data: {
            page_id: pageId
        },
        beforeSend: function() {
            $('.price-loader').show();
        },
        success: function(json) {
            if (json.prices.length) {
                appendPriceRange(json);
            } else {
                rowPrice.hide();
            }
        },
        complete: function() {
            $('.price-loader').hide();
        }
    });
}

if (productsContainer.length) {
    $(document).on('click','.resource-page-prev', function(event) {
        page = page + 1;
        event.preventDefault();
        setPaginationParams('push');
        processProductRequest();
    });
    $(document).on('click', '.resource-page-next', function(event) {
        event.preventDefault();
        page = page + 1;
        setPaginationParams('push');
        processProductRequest();
    });
    $(document).on('click', '.pagination-trigger', function(event) {
        event.preventDefault();
        page = $(this).attr('data-page');
        setPaginationParams('push');
        processProductRequest();
    });
}

if (variants.length) {
    setVariantId(variants[0].id)
}

function setVariantId(variant_id) {
    $variantId = parseInt(variant_id) || 0;
}

// Variants
$(document).on('change', '.variants', function(event) {
    var ignoredOption = $(this).attr('id');
    var ignoredValue = $(this).val();
    var variantsSelectors = $('.variants');
    var selectedOptions  = {};
    var strings = [];
    var indexes = [];
    variantsSelectors.each(function(i, e) {
        indexes.push('option' + (i+1));
        if ($(e).val()) {
            selectedOptions['option' + (i+1)] = $(e).val();
        }
    });
    $.each(variants, function(index, object) {
        var str = '';
        $.each(indexes, function(index, value) {
            str += value + ':' + object[value] + ',';
        });
        strings.push(str);
    });
    variantsSelectors.each(function(index, element) {
        // "<select>...</select>"
        var currentOption = $(element).attr('id');
        // var currentValue = $(element).val();
        if (currentOption !== ignoredOption || $(element).val() === '') {
            $('option', element).attr('disabled', true);
            $.each($('option', element), function(index, option) {
                var optionOption = currentOption;
                var optionValue = $(option).val();
                if (optionValue === '') {
                    $(option).attr('disabled', false);
                }
                $.each(strings, function(index, value) {
                    // "<option>...</option>"
                    var searchObject = $.extend({}, selectedOptions);
                    searchObject[optionOption] = optionValue;
                    var l = Object.keys(searchObject).length;
                    var f = 0;
                    $.each(searchObject, function(index, option) {
                        var search = index + ':' + option + ',';
                        if (value.indexOf(search) > -1) {
                            f = f +1;
                        }
                    });
                    if (l === f) {
                        $(option).attr('disabled', false);
                    }
                });
            });
        }
    });

    var allSet = true;
    variantsSelectors.each(function(i, e) {
        if (!$(e).val()) {
            allSet = false;
        }
    });

    var selectedObject = false;
    if (allSet === true) {
        $.each(variants, function(index, variantObject) {
            var objectFound = true;
            variantsSelectors.each(function(i, e) {
                if (variantObject[$(e).attr('id')] !== $(e).val()) {
                    objectFound = false;
                }
            });
            if (objectFound === true) {
                selectedObject = variantObject;
            }
        });
    }

    if (typeof selectedObject === 'object') {
        $('.productPrice').html(selectedObject.currentPrice);
        $('.productRetailPrice').html(selectedObject.retailPrice);
        $('.productStock').html(selectedObject.currentStock);
        $('.ordersPlacedAmount').html(selectedObject.ordersPlacedAmount);
        if (selectedObject.sku !== '') {
            $('.productSku').html(selectedObject.sku);
        } else {
            $('.productSku').html('-');
        }
        setVariantId(selectedObject.id);
        var quantityHtml = '';
        if (selectedObject.quantityManagement === 'steps') {
            var quantitySteps = selectedObject.steps.split(',');
            quantityHtml = '<select class="form-control" required="required" name="quantity">';
            $.each(quantitySteps, function(index, value) {
                quantityHtml += '<option value="'+value+'">'+value+'</option>';
            });
            quantityHtml += '</select>';
        } else {
            var minimum = parseInt(selectedObject.minimum) <= 0 ? '1' : selectedObject.minimum;
            quantityHtml = '<input class="form-control" required="required" name="quantity" type="text" value="'+minimum+'">';
        }
        $('.productOrderQuantity').html(quantityHtml);
    }
});

// Cart
$(function() {
    var isLoading = false;
    $('.dropdown-cart').on('show.bs.dropdown', function() {
        if (isLoading === false) {
            $.ajax({
                headers: {'X-CSRF-TOKEN': shopToken},
                type: 'GET',
                url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/load-cart',
                dataType: 'json',
                data: {},
                beforeSend: function() {
                    isLoading = true;
                },
                success: function(json) {
                    if (json.length) {
                        $('.cart-items').html(json);
                    } else {
                        $('.cart-items').html('');
                    }
                },
                complete: function() {
                    isLoading = false;
                }
            });
        }
    });

    $('.dropdown-cart .dropdown-menu').on('click.bs.dropdown', function (event) {
        event.stopPropagation();
    });
});

//Show Specifications PDP Popup
$(function (){
    $(document).on('click', 'a[data-action="show-product-specifications"]', function(event) {
        $('#product-specifications').modal('show');
    });
})

// Add product to cart
$(function() {
    var isLoading = false;
    $(document).on('click', 'a[data-action="add-to-cart"]', function(event) {
        event.preventDefault();
        var $this = $(this);
        var variant_id = $this.attr('data-variant-id');
        var $formAdd = $this.closest('#form-add');

        if (variant_id && !$formAdd) {
            $.ajax({
                headers: {'X-CSRF-TOKEN': shopToken},
                type: 'POST',
                url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/cart/add',
                dataType: 'json',
                data: {
                    variant_id: variant_id
                },
                beforeSend: function() {
                    isLoading = true;
                    $('#cart-overlay .co-shipping').hide();
                    $('#cart-overlay .co-other-products').hide();
                },
                success: function(json) {
                    if (json.success === true) {
                        var $cartItemCount = $('.cart-item-count');
                        var $cartItem = $('.cart-icon');
                        if ($cartItemCount.length) {
                            var $total = $cartItemCount.first().text();
                            $cartItemCount.text(parseInt($total) + 1);
                        } else {
                            $cartItem.html('<div class="cart-item-count">1</div>');
                            $('.cart-button').show();
                        }
                        $cartItem.addClass('cart-active');

                        $('#cart-overlay .co-title').text(json.data.product_title);
                        $('#cart-overlay .co-price span').text(json.data.product_price);
                        $('#cart-overlay .co-image-wrap .co-image').css({'background-image' : 'url("' +  json.data.product_image + '")'});
                        $('#cart-overlay .co-totals span').text(json.data.cart_total);
                        if (String(json.data.shipping_message).length) {
                            $('#cart-overlay .co-shipping').show();
                            $('#cart-overlay .co-shipping span').text(json.data.shipping_message);
                        }
                        if ($('#cart-overlay .co-other-products').length) {
                            if (json.other_products.length) {
                                $.each(json.other_products, function(index, value) {
                                    $('#cart-overlay .co-other-products .co-row-products').append(value).find('.product-item').last().fadeIn(100);
                                });
                                $('#cart-overlay .co-other-products').show();
                            }
                        }
                        $('#cart-overlay').modal('show');
                    } else {
                        console.log(json.message);
                    }
                },
                complete: function() {
                    isLoading = false;
                }
            });
        } else {
            var $alertBlock = $('.alert-block');
            $alertBlock.hide();
            $alertBlock.empty();
            $.ajax({
                headers: {'X-CSRF-TOKEN': shopToken},
                type: 'POST',
                url: shopUrl + '/' + FRONTEND_LANGUAGE + '/cart',
                dataType: 'json',
                data: $formAdd.serialize(),
                beforeSend: function() {
                    isLoading = true;
                    $this.attr('disabled', true);
                    $('#cart-overlay .co-shipping').hide();
                    $('#cart-overlay .co-other-products').hide();
                },
                success: function(json) {
                    if (json.success === true) {
                        var $cartItemCount = $('.cart-item-count');
                        var $cartItem = $('.cart-icon');
                        if ($cartItemCount.length) {
                            var $total = $cartItemCount.first().text();
                            $cartItemCount.text(parseInt($total) + 1);
                        } else {
                            $cartItem.html('<div class="cart-item-count">1</div>');
                            $('.cart-button').show();
                        }
                        $cartItem.addClass('cart-active');

                        $('#cart-overlay .co-title').text(json.data.product_title);
                        $('#cart-overlay .co-price span').text(json.data.product_price);
                        $('#cart-overlay .co-image-wrap .co-image').css({'background-image' : 'url("' +  json.data.product_image + '")'});
                        $('#cart-overlay .co-totals span').text(json.data.cart_total);
                        if (String(json.data.shipping_message).length) {
                            $('#cart-overlay .co-shipping').show();
                            $('#cart-overlay .co-shipping span').text(json.data.shipping_message);
                        }
                        if ($('#cart-overlay .co-other-products').length) {
                            if (json.other_products.length) {
                                $.each(json.other_products, function(index, value) {
                                    $('#cart-overlay .co-other-products .co-row-products').append(value).find('.product-item').last().fadeIn(100);
                                });
                                $('#cart-overlay .co-other-products').show();
                            }
                        }
                        $('#cart-overlay').modal('show');
                    } else {
                        $alertBlock.html('<div class="alert alert-danger fade in">' + json.message + '</div>');
                        $alertBlock.show();
                    }
                },
                complete: function() {
                    isLoading = false;
                    setTimeout(function() {
                        $this.attr('disabled', false);
                    }, 300);
                }
            });
        }
    });

    var isSearchLoading = false;
    var $searchTimeout = null;
    $(document).on('keyup focus', '.form-search input[name="q"]', function() {
        clearTimeout($searchTimeout);
        var $this = $(this);
        var $q = String($this.val()).trim();

        var $form = $this.closest('.form-search');
        var $sdContent = $form.find('.search-dropdown-menu .sd-content');
        var $sdFooter = $form.find('.search-dropdown-menu .sd-footer');
        var $sdMenu = $form.find('.search-dropdown-menu');
        var $sdToggle = $form.find('.search-dropdown-toggle');

        $sdContent.html('');
        $sdFooter.hide();
        if (!$sdMenu.is(':hidden')){
            $sdToggle.trigger('click');
        }

        if (String($q).length >= 3) {
            $searchTimeout = setTimeout(function() {
                $.ajax({
                    headers: {'X-CSRF-TOKEN': shopToken},
                    type: 'GET',
                    url: shopUrl + '/' + FRONTEND_LANGUAGE + '/ajax/search',
                    dataType: 'json',
                    data: {
                        q: $q
                    },
                    beforeSend: function() {
                        isSearchLoading = true;
                    },
                    success: function(json) {
                        var $html = '';
                        if (json.length === 0) {
                            $html += '<div class="sd-product-wrap">' +
                                '<div class="sd-flex flex-center">' +
                                '<p>Søgningen gav ikke nogen resultater.</p>' +
                                '<p>Forsøg med et andet søgeord.</p>' +
                                '</div>' +
                                '</div>';
                        } else {
                            $.each(json, function(index, value) {
                                $html += '<div class="sd-product-wrap">' +
                                    '<div class="sd-flex sd-flex-1">' +
                                    '<a href="' + value.handle + '" class="sd-product-image" title="' + value.title + '" style="background-image: url(' + value.image + ')"></a>' +
                                    '</div>' +
                                    '<div class="sd-flex sd-flex-2">' +
                                    '<a href="' + value.handle + '" title="' + value.title + '">' +
                                    '<p>' + value.vendor + '</p>' +
                                    '<p>' + value.title + '</p>' +
                                    '<p>' + value.price + '</p>' +
                                    '</a>' +
                                    '</div>' +
                                    '</div>';
                            });
                            if (json.length === 5) {
                                $sdFooter.show();
                            }
                        }
                        $sdContent.html($html);
                    },
                    complete: function() {
                        isSearchLoading = false;
                        if ($sdMenu.is(':hidden')){
                            $sdToggle.trigger('click');
                        }
                    }
                });
            }, 300);
        }
    });

    $(document).on('click', '.search-dropdown-menu', function(event) {
        event.stopPropagation();
    });

    $(document).on('click', '.search-dropdown-toggle', function(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    });

    $(document).on('click', '.search-more', function(event) {
        event.preventDefault();
        $(this).closest('.form-search').find('form').submit();
    });
});

// Toggle filters
$(function() {
    $(document).on('click', 'div[data-toggle="filter"]', function(event) {
        event.preventDefault();
        $(this).closest('.filter-wrap').toggleClass('filter-active');
    });
});

$(document).ready(function() {
    var $confirmOrder = $('#confirm-order');
    if ($confirmOrder.length) {
        $confirmOrder.on('click', function(event) {
            event.preventDefault();
            var $this = $(this);
            if ($this.attr('disabled') !== 'disabled') {
                $this.attr('disabled', 'disabled');
                $('#submit').click();
            }
        });
    }
    var $formConfirm = $('#form-confirm');
    if ($formConfirm.length) {
        $(':input', $formConfirm).on('change', function() {
            $confirmOrder.attr('disabled', false);
        });
    }
});

$(function() {
    $(".header-bottom-menu .flex-menu .header-side-left .dropdown").hover(
        function(){ $(this).addClass('open') },
        function(){ $(this).removeClass('open') }
    );
});

function toggleFixedHeader() {
    var $offset = 3;
    var $headerFixedWrap = $('.header-fixed-wrap');
    if ($headerFixedWrap.length) {
        var $oldTop = $headerFixedWrap.css('top');
        var $headerHeight = $('header.header').outerHeight();
        var $scrollHeight = $(window).scrollTop();
        var $windowWidth = $(window).width();
        var $top = 0;
        if ($headerHeight >= $scrollHeight || $windowWidth < 992) {
            $top = ($headerFixedWrap.outerHeight() + $offset) * -1;
        }
        $top = $top + 'px';
        if ($oldTop !== $top) {
            $('.dropdown-categories-brands').removeClass('open');
            $('.dropdown-pages').removeClass('open');
        }

        $headerFixedWrap.css({'top': $top});
    }
}

$(document).ready(function() {
    toggleFixedHeader();
});

$(window).on('scroll', function() {
    toggleFixedHeader();
});

$(window).on('resize', function() {
    toggleFixedHeader();
});

function getVariantById(variant_id) {
    variant_id = parseInt(variant_id) || 0;
    var variant = null;
    $.each(variants, function(index, object) {
        if (parseInt(object.id) === variant_id) {
            variant = object;
        }
    });

    return variant;
}

$(document).on('change keyup', 'input[name="quantity"]', function() {
    var quantity = parseInt($(this).val()) || 0;
    if (variants.length >= 1) {
        var price = '';
        var variant = getVariantById($variantId);
        if (variant.step_prices === '1') {
            if (quantity < variant.step1_qty) {
                price = variant.currentPrice;
            } else if (quantity >= variant.step1_qty && quantity < variant.step2_qty) {
                price = variant.step1_price;
            } else if (quantity >= variant.step2_qty && quantity < variant.step3_qty) {
                price = variant.step2_price;
            } else if (quantity >= variant.step3_qty) {
                price = variant.step3_price;
            } else {
                price = variant.currentPrice;
            }
            $('.productPrice').html(price);
        }
    }
});

$(document).on('click', '.carousel-arrow-down', function(event) {
    event.preventDefault();
    $(this).removeClass('animation-bounce');
    var $headerMenu = $('.header-fixed-wrap');
    var $carouselBlock = $('.container-carousel');
    var $offset = $carouselBlock.outerHeight() + $carouselBlock.offset().top - $headerMenu.outerHeight();
    $('html, body').animate({scrollTop: $offset}, 700, 'swing');
});

var $cartDropdown = $('.dropdown-cart');

$cartDropdown.on('show.bs.dropdown', function() {
    var $this = $(this);
    var $offset = $this.find('.dropdown-toggle').offset();
    var $dropdown = $this.find('.dropdown-menu');
    if ($(window).width() <= 767) {
        $dropdown.css({
            'right': 'unset',
            'left': '-' + $offset.left + 'px',
            'width': '100vw'
        });
    } else {
        $dropdown.css({
            'right': '',
            'left': '',
            'width': ''
        });
    }
});

$cartDropdown.on('hide.bs.dropdown', function() {
    var $this = $(this);
    var $dropdown = $this.find('.dropdown-menu');
    if ($(window).width() <= 767) {
        $dropdown.css({
            'right': '',
            'left': '',
            'width': ''
        });
    }
});
